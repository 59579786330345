<template>
  <div>
    <a-modal width="500px" :title="Title" :visible="visible" :ok-button-props="{ danger: true }" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="cancel">
      <a-form ref="formRef" :model="formState" :rules="rules">
        <a-form-item label="角色名称：" name="roleName">
          <a-input v-model:value="formState.roleName" style="width: 370px;" />
        </a-form-item>
        <a-form-item label="角色描述：" name="description">
          <a-input v-model:value="formState.description" style="width: 370px;" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { role_post, role_put } from '@/api/system'
const form = {
  roleName: '',
  description: ''
}
export default defineComponent({
  name: 'index',
  props: {
    Title: {
      type: String,
      required: false
    },
    FormData: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      options: [
        {
          value: 'role',
          label: '菜单'
        },
        {
          value: 'dir',
          label: '目录'
        },
        {
          value: 'button',
          label: '按钮'
        }
      ],
      replaceFields: {
        title: 'name',
        key: 'id',
        value: 'id'
      },
      visible: false,
      formState: {
        ...form
      },
      rules: {
        roleName: [{ required: true, message: '不能为空', trigger: 'change' }],
        description: [
          { required: true, message: '不能为空', trigger: 'change' }
        ]
      },
      editor: false
    }
  },
  components: {},
  created() {},
  methods: {
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = { ...form }
      this.editor = false
      this.visible = false
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        if (!this.editor) {
          role_post(this.formState).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.cancel()
            this.$parent.refresh()
          })
        } else {
          role_put(this.formState).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.cancel()
            this.$parent.refresh()
          })
        }
      })
    },
    showModal(val) {
      this.visible = true
      if (val) {
        this.editor = true
        this.formState = { ...form, ...val }
      } else {
        this.editor = false
      }
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
    