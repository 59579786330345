import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-wrapper question-type" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_pagaTable = _resolveComponent("pagaTable")!
  const _component_popupWindow = _resolveComponent("popupWindow")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_pagaTable, {
      ref: "table",
      columns: _ctx.purchaseColumns,
      sourceData: _ctx.getDataApi,
      "row-key": "examinationPaperId",
      onChange: _ctx.handleChange
    }, {
      headerLeft: _withCtx(() => [
        _createVNode(_component_a_button, {
          type: "default",
          class: "ant-btn-add",
          onClick: _ctx.handleAdd
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 新增 ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      operation: _withCtx(({ text, record }) => [
        (record.roleName!=='安全员')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_a_button, {
                type: "link",
                onClick: ($event: any) => (_ctx.handleClick(text, record))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("编辑")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "link",
                onClick: ($event: any) => (_ctx.authorization(record))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("授权")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_a_popconfirm, {
                title: "确定删除吗",
                "ok-text": "是的",
                "cancel-text": "关闭",
                onConfirm: ($event: any) => (_ctx.del(record))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, { type: "link" }, {
                    default: _withCtx(() => [
                      _createTextVNode("删除")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["onConfirm"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns", "sourceData", "onChange"]),
    _createVNode(_component_popupWindow, {
      ref: "popupWindow",
      Title: _ctx.Title
    }, null, 8, ["Title"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
      title: "权限授权",
      onOk: _ctx.handleOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tree, {
          checkable: "",
          "tree-data": _ctx.treeData,
          checkedKeys: _ctx.checkedKeys,
          "onUpdate:checkedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedKeys) = $event)),
          multiple: "",
          checkStrictly: "",
          "replace-fields": _ctx.replaceFields
        }, null, 8, ["tree-data", "checkedKeys", "replace-fields"])
      ]),
      _: 1
    }, 8, ["visible", "onOk"])
  ]))
}